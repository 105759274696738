<template>
  <app-modal
    modal-class="slide-up"
    ref="modal_create_user"
    size="lg"
    :on-hide="clearEntryToSave"
    :modal-header-title="$t('users.create user')"
  >
    <template v-slot:container>
      <validation-observer
        ref="form_create_entry"
        v-slot="{ passes, valid, validated }"
      >
        <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
          <table
            class="table dataTable app-table-form app-colspan app-colspan-10"
          >
            <tr>
              <th class="app-label app-align-middle app-w-200 p-l-10">
                {{ $t("common.name") }} <span class="app-require">＊</span>
              </th>
              <td>
                <app-input
                  name="name"
                  type="string"
                  rules="required"
                  v-model="request.name"
                ></app-input>
              </td>
            </tr>
            <tr>
              <th class="app-label app-align-middle app-w-200 p-l-10">
                {{ $t("common.mail address") }}
                <span class="app-require">＊</span>
              </th>
              <td>
                <app-input
                  name="email"
                  type="email"
                  :rules="VALIDATE.V_EMAIL_REQUIRED"
                  v-model="request.email"
                ></app-input>
              </td>
            </tr>
            <tr>
              <th class="app-label app-align-middle app-w-200 p-l-10">
                {{ $t("users.role") }}
                <span class="app-require">＊</span>
              </th>
              <td>
                <app-select
                  name="role"
                  rules="required"
                  :label="$t('users.role')"
                  :options-data="[{ id: ROLES.ADMIN, name: $t('users.admin') }]"
                  v-model="request.role"
                >
                </app-select>
              </td>
            </tr>
            <tr>
              <th class="app-label app-align-middle app-w-200 p-l-10">
                {{ $t("common.status") }}
              </th>
              <td>
                <app-radio
                  class="app-inline"
                  val="1"
                  :label="$t('common.active')"
                  name="TO_plan"
                  v-model="request.active"
                >
                </app-radio>
                <app-radio
                  class="app-inline"
                  val="0"
                  :label="$t('common.deactive')"
                  name="TO_plan"
                  v-model="request.active"
                >
                </app-radio>
              </td>
            </tr>
          </table>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button
              :disabled="!valid && validated"
              type="submit"
              :class="['app-btn app-btn-blue']"
            >
              {{ $t("common.save") }}
            </button>
            <button
              type="button"
              :class="['app-btn app-btn-orange']"
              data-dismiss="modal"
            >
              {{ $t("common.cancel") }}
            </button>
          </div>
        </form>
      </validation-observer>
    </template>
  </app-modal>
</template>
<script>
export default {
  name: "CreateComponent",

  data() {
    return {
      request: {
        name: "",
        email: "",
        active: 1,
        role: "",
      },
    };
  },

  methods: {
    show() {
      this.$refs.modal_create_user.show();
    },

    async saveEntry() {
      let { name, email, active, role } = this.request;
      let response = await this.$request.post(this.ENDPOINT.USERS_SAVE, {
        name,
        email,
        active,
        role,
      });
      if (!response.hasErrors()) {
        this.__noticeSuccess(this.$t("common.msg create ok"));

        this.$refs.modal_create_user.hide();
        this.request = { active: 1 };
        this.$emit("onSuccess", response);
      } else {
        if (response.data?.errors) {
          this.$refs.form_create_entry.setErrors(response.data.errors);
        } else if (response.data.error_msg) {
          this.$refs.form_create_entry.setErrors({
            email: [response.data.error_msg],
          });
        } else {
          this.__noticeError();
        }
      }
    },

    clearEntryToSave() {
      this.$refs.form_create_entry.reset();
    },
  },
};
</script>
