<template>
  <div class="card card-body shadow-sm listall-account">
    <app-modal-confirm
      ref="modal_mail_confirm"
      :modal-header-title="$t('announcements.confirm_title')"
      @confirmed="sendMail()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("accounts.confirm_send_mail_verify") }}</p>
      </template>
    </app-modal-confirm>
    <app-basic-table
      ref="basic_table"
      :table-name="$t('users.user list')"
      :filters.sync="filters"
      :setting-columns="columns"
      :endpoint-list="ENDPOINT.USERS_LIST"
      @resetAllSearch="onResetAllSearch"
      @searched="onSearch"
    >
      <template v-slot:filters>
        <div class="row">

          <div class="col-xl-6 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.name_like.label }}</label>
              <app-input :name="filters.name_like.value" input-style="normal"
                         v-model="filters.name_like.value"
              />
            </div>
          </div>
          <div class="col-xl-6 col-md-3 col-sm-3">
            <div class="form-group">
              <label>{{ filters.email_like.label }}</label>
              <app-input :name="filters.email_like.value" input-style="normal"
                         v-model="filters.email_like.value"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:table-menu-right>
        <button @click="handleBtnCreateClick()" class="btn btn-warning">
          {{ $t("common.create") }}
        </button>
      </template>

      <template v-slot:body-cell-status_enabled="props">
        <td class="app-align-middle">
          {{ getActiveText(props.row.status_enabled) }}
        </td>
      </template>

      <template v-slot:body-cell-email="props">
        <td class="app-align-middle" v-if="props.row.email_verified_at === null">
          <p :data-original-title="props.row.email" class="app-table-p app-cell-tooltip mb-0" style="color: red">{{ props.row.email }}</p>
        </td>
      </template>

      <template v-slot:body-cell-unverify="props">
        <td class="app-align-middle text-center" v-if="props.row.email_verified_at === null">
          <button @click="handleBtnSendMailVerify(props.row)" class="btn btn-danger">{{$t("common.unverify")}}</button>
        </td>
      </template>

      <template v-slot:body-cell-updated_at="props">
        <td class="app-align-middle text-center ">
          {{ props.row.updated_date }}
        </td>
      </template>

      <template v-slot:body-cell-edit="props">
        <td class="app-align-middle text-center app-p-none">
          <button
            @click="handleBtnUpdateClick(props.row)"
            class="btn btn-primary"
          >
            {{ $t("common.edit") }}
          </button>
        </td>
      </template>
    </app-basic-table>
  </div>
</template>
<script>
import AppBasicTable from "@components/_common/list/AppBasicTable";
import CreateComponent from "@pages/admin/users/_partials/CreateComponent";
import UpdateComponent from "@pages/admin/users/_partials/UpdateComponent";
import {ROLES, BASIC_TABLE} from "@constants";
import AppRadio from "../../../components/forms/AppRadio";

export default {
  name: "ListAllAccount",

  components: {
    AppRadio,
    "app-basic-table": AppBasicTable,
    "create-component": CreateComponent,
    "update-component": UpdateComponent,
  },

  data() {
    return {
      idCache: null,
      paramGetList: {},
      accountId: null,
      filters: {
        status_equal: {
          name: "status_enabled",
          condition: "equal",
          label: this.$t("common.status"),
          label_option: {
            all: this.$t("common.all"),
            active: this.$t("common.active"),
            deactive: this.$t("common.deactive"),
          }
        },
        name_like: {
          name: "name",
          condition: "like",
          label: this.$t("common.name")
        },
        email_like: {
          name: "email",
          condition: "like",
          label: this.$t("common.email")
        },
        apartment_id_equal: {
          name: "apartment_id",
          condition: "equal",
          label: this.$t("accounts.filter_apartment"),
        },
      },

      columns: [
        {name: "id", label: this.$t("common.id"), sortable: true, textAlign: 'text-center'},
        {name: "status_enabled", label: this.$t("common.status"), sortable: true},
        {name: "email", label: this.$t("common.mail address")},
        {name: "unverify", label: this.$t("common.unverify")},
        {name: "name", label: this.$t("common.name"), sortable: true},
        {name: "created_at", label: this.$t("common.created at"), sortable: true, textAlign: 'text-center'},
        {name: "updated_at", label: this.$t("common.updated at"), sortable: true, textAlign: 'text-center'},
        {name: "edit", label: this.$t("common.edit")},
      ],
      meta : [

      ]
    };
  },

  mounted: function () {
    this.$request.get(this.ENDPOINT.UI_HELPER_ACCOUNTS).then(res => {
      let  apartments = res.data.apartments;
      apartments.unshift({
        id: 'all',
        name: '',
      });
      this.meta = {
        apartments: apartments,
        status: res.data.status,
      }
    })
  },

  methods: {
    getRoleText(role) {
      if (role == ROLES.ADMIN) {
        return this.$t("users.admin");
      } else {
        return this.$t("users.user");
      }
    },

    getActiveText(active) {
      if (active) {
        return this.$t("common.active");
      } else {
        return this.$t("common.deactive");
      }
    },
    handleBtnUpdateClick(entry) {
      this.$router.push({name: this.ROUTES.ADMIN.ACCOUNT_EDIT,params:  {id: entry.id}})
    },
    handleBtnCreateClick() {
      this.$router.push({name: this.ROUTES.ADMIN.ACCOUNT_CREATE})
    },
    handleBtnSendMailVerify(value) {
      this.accountId = value.id
      this.$refs.modal_mail_confirm.show();
    },
    async sendMail() {
      await this.$request.post(this.ENDPOINT.RESENT_ACTIVE_EMAIL_ADMIN(this.accountId))
      this.$refs.modal_mail_confirm.hide();
      await this.$refs.basic_table.getEntries();
    },
    onResetAllSearch() {
      this.$router.push({name: this.$route.name, query: {'filters.status_enabled.equal': 'all','filters.apartment_id.equal': 'all'}})
      this.filters.name_like.value = '';
      this.filters.email_like.value = '';
    },
    onSearch() {
      // this.getTourSelectName();
    },
  },
};
</script>
